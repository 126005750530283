export default function GameHeader(gameId) {
    return (
        <div className="game-header">
            <div className="left">
            </div>
            <div className="center">
                Room Code: {gameId.gameId}
            </div>
            <div className="right"></div>
        </div>
    );
}
