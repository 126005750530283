import { create } from 'zustand'

const useBoardsStore = create((set) => ({
    boardsTiles: new Map(),
    setTileValueInBoard: (boardId, x, y, newState) => set((state) => ({
        ...state, boardsTiles: (() => {
            const newBoards = new Map(state.boardsTiles);
            if (newBoards.get(boardId) === undefined) {
                // Board not set yet, create it
                newBoards.set(boardId, new Map());
                newBoards.get(boardId).set(`${x},${y}`, {xPos: x, yPos: y, state: newState});
            } else {
                // Board already exists
                if (state.boardsTiles.get(boardId).get(`${x},${y}`)?.state === newState) {
                    // Tile state hasn't changed, don't update state
                    // TODO Check we don't need to update for tile positions etc?
                    return state.boardsTiles;
                }

                newBoards.get(boardId).set(`${x},${y}`, {xPos: x, yPos: y, state: newState});
            }
            return newBoards;
        })(),
    })),
    boardsMeta: new Map(),
    setBoardsMeta: (boardId, width, height, playerName, colour) => set((state) => ({
        ...state, boardsMeta: (() => {
            if (state.boardsMeta.get(boardId) !== undefined) {
                // Only update state if it's not already set
                return state.boardsMeta;
            }
            const newBoardsMeta = new Map(state.boardsMeta);
            newBoardsMeta.set(boardId, 
                {
                    width: width, 
                    height: height,
                    playerName: playerName,
                    colour: colour
                });
            return newBoardsMeta;
        })(),
    })),
    boardProgress: new Map(),
    setBoardProgress: (boardId, playerName, percentComplete, numberOfBombs, boardState) => set((state) => ({
        ...state, boardProgress: (() => {
            const newBoardProgress = new Map(state.boardProgress);
            if (state.boardProgress.get(boardId) === undefined) {
                newBoardProgress.set(boardId, 
                    {
                        playerName: playerName,
                        percentComplete: percentComplete,
                        numberOfBombs: numberOfBombs,
                        boardState: boardState
                    });
                return newBoardProgress;
            }
            if (state.boardProgress.get(boardId).playerName === playerName &&
                state.boardProgress.get(boardId).percentComplete === percentComplete &&
                state.boardProgress.get(boardId).numberOfBombs === numberOfBombs &&
                state.boardProgress.get(boardId).boardState === boardState
            ) {
                return state.boardProgress;
            }
            newBoardProgress.set(boardId, 
                {
                    playerName: playerName,
                    percentComplete: percentComplete,
                    numberOfBombs: numberOfBombs,
                    boardState: boardState
                });
            return newBoardProgress;
        })(),
    })),
    gameMeta: {},
    setGameMeta: (gameId, gameState, winner, startingCountdown) => set((state) => ({
        ...state, gameMeta: (() => {
            // If nothing has changed, don't update state
            if (state.gameMeta.gameId === gameId &&
                state.gameMeta.gameState === gameState &&
                state.gameMeta.winner === winner &&
                state.gameMeta.startingCountdown === startingCountdown
            ) {
                return state.gameMeta;
            }
            let newGameMeta = {
                gameId: gameId,
                gameState: gameState,
                winner: winner,
                startingCountdown: startingCountdown
            };
            return newGameMeta;
        })(),
    })),
    reset: () => set((state) => ({
        ...state, boardsTiles: new Map(), boardsMeta: new Map(), boardProgress: new Map(),
    })),
}));

export default useBoardsStore;
