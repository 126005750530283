import useBoardsStore from './BoardsStore';
import { useShallow } from 'zustand/react/shallow'

export default function ProgressBars() {
    const boardProgress = useBoardsStore(useShallow(state => state.boardProgress));

    return (
        <div className="progress-bars">
            {Array.from(boardProgress.entries()).sort(([, a], [, b]) => b.percentComplete - a.percentComplete).slice(0,3).map(([key, board]) => (
                <ProgressBar key={key} boardProgress={board} boardId={key} />
            ))}
        </div>
    );
}

function ProgressBar({ boardProgress, boardId }) {
    const boardMeta = useBoardsStore(useShallow(state => state.boardsMeta.get(boardId)));
    const percentage = boardProgress.percentComplete;
    var className = "progress-bar";
    if (percentage > 90) {
        className += " almost-complete";
    }
    return (
        <div className="row">
            <div className="name">{boardProgress.playerName}</div>
            <div className="percentage">{percentage}%</div>
            <div className={className}>
                <div className="progress" style={{
                    width: `${percentage}%`,
                    backgroundColor: `${boardMeta.colour}`,
                }}></div>
            </div>
        </div>
    )
}