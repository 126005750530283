import useBoardsStore from './BoardsStore';
import ProgressBars from './ProgressBars';
import { useShallow } from 'zustand/react/shallow'
import axios from 'axios';

export default function GameFooter(gameId) {
    const boardMeta = useBoardsStore(useShallow(state => state.gameMeta));

    const handleStartClick = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + '/v1/games/' + gameId.gameId + '/start', {})
          .catch(error => {
            console.error('There was an error starting game', error);
          });
    };

    if (boardMeta.gameState === "created") {
        return (
            <div className="game-footer">
                <div className="left"></div>
                <div className="center">
                    <button className="button" onClick={handleStartClick}>Start Game!</button>
                </div>
                <div className="right"></div>
                
            </div>
        );
    }

    if (boardMeta.gameState === "starting" || boardMeta.gameState === "started") {
        return (
            <div className="game-footer">
                <div className="left"></div>
                <div className="center">
                    <ProgressBars />
                </div>
                <div className="right"></div>
            </div>
        );
    }
}
