import axios from 'axios';
import useBoardsStore from './BoardsStore';
import { useShallow } from 'zustand/react/shallow'
import { useState, useRef } from 'react';
import useUserSettingsStore from './UserSettingsStore';

function Tile({ x, y, boardId, gameId}) {
  const setTileValueInBoard = useBoardsStore(state => state.setTileValueInBoard);
  const setBoardsMeta = useBoardsStore(state => state.setBoardsMeta);
  const setBoardsProgress = useBoardsStore(state => state.setBoardProgress);
  const username = useUserSettingsStore(state => state.username);
  
  const pressedRef = useRef(false);
  const [timerId, setTimerId] = useState(null);
  const shouldRevealRef = useRef(true);
  
  const handleTouchStart = () => {
    pressedRef.current = true;
    const id = setTimeout(() => {
      if (pressedRef.current) {
        doAction(x, y, actionType.FLAG);
        shouldRevealRef.current = false;
      }
    }, 250);
    setTimerId(id);
  };

  const handleClick = (e) => {   
    if (shouldRevealRef.current) {
      doAction(x, y, actionType.REVEAL);
    }
    shouldRevealRef.current = true;
  }
  
  const handleTouchEnd = () => {
    pressedRef.current = false;
    clearTimeout(timerId);
  };
  // Function to make action call to backend
  function doAction(x, y, actionType) {
    axios.post(process.env.REACT_APP_BACKEND_URL + '/v1/games/' + gameId + '/boards/' + boardId + '/actions', {
      "xPos": x,
      "yPos": y,
      "type": actionType,
      "playerId": username
    })
    .then(response => {
      for (let board of response.data.boards) {
        for (let tile of board.tiles) {
            setTileValueInBoard(board.id, tile.xPos, tile.yPos, tile.state);
        }
        setBoardsMeta(board.id, board.boardOptions.width, board.boardOptions.height, board.playerName, board.boardOptions.colour);
        setBoardsProgress(
            board.id,
            board.boardOptions.playerId,
            100 - Math.round(board.numberOfRemainingTiles / board.numberOfTiles * 100),
            board.numberOfRemainingBombs,
            board.state
        );
    }
    })
    .catch(error => {
      console.error('There was an error sending actions', error);
    });
  }

  const tile = useBoardsStore(useShallow(state => state.boardsTiles.get(boardId).get(`${x},${y}`)));
  var value = "";
  switch (tile.state) {
    case "flag":
      value = "🚩";
      break;
    case "bomb":
      value = "💣";
      break;
    case "hidden":
      value = "";
      break;
    case "empty":
      value = "";
      break;
    default:
      value = tile.state;
      break;
  }

  const boardProgress = useBoardsStore(useShallow(state => state.boardProgress.get(boardId)));

  let className = "tile"
  
  if (boardProgress.boardState === "failed") {
    className += " vibrate";
  }
  
  if (tile.state === "hidden") {
    className += " hidden";
  } else {
    className += " revealed";
  }

  const boardMeta = useBoardsStore(useShallow(state => state.boardsMeta.get(boardId)));

  return (
    <button 
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleTouchStart}
      onMouseUp={handleTouchEnd}
      onClick={handleClick}
      className={className}
      style={{backgroundColor: boardMeta.colour}}
      onContextMenu={(e) => {
        e.preventDefault(); // Prevent the context menu from showing up
      }}
    >
      {value}
    </button>
  );
}
  
function BoardRow({y, width, boardId, gameId}) {
  return (
    <div className="board-row">
      {Array.from({ length: width }).map((_, x) => (
        <Tile 
          key={x+","+y}
          x={x}
          y={y}
          boardId={boardId}
          gameId={gameId}
        />
      ))}
    </div>
  )
}

function BoardStats({ boardId }) {
  const boardProgress = useBoardsStore(useShallow(state => state.boardProgress.get(boardId)));
  if (boardProgress === undefined) {
    return <div>Loading board stats...</div>;
  }

  return (
    <div className="board-stats">
      <div className="board-stats-player">{boardProgress.playerName}</div>
      <div className="board-stats-tiles"> {boardProgress.percentComplete}%</div>
      <div className="board-stats-bombs">{boardProgress.numberOfBombs}💣</div>
    </div>
  )
}
  
let actionType = {
  REVEAL: "reveal",
  FLAG: "flag"
}
  
export default function Board({ id, gameId }) {
  const boardMeta = useBoardsStore(useShallow(state => state.boardsMeta.get(id)));

  if (boardMeta === undefined) {
    return <div>Loading board...</div>;
  }

  return (
    <>
      <div className="board-rows">
        {Array.from({ length: boardMeta.height }).map((_, y) => (
          <BoardRow key={y} y={y} width={boardMeta.width} boardId={id} gameId={gameId} />
        ))}
      </div>
      <BoardStats boardId={id} /> 
    </>
  );
}
