import Board from './Board';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import useBoardsStore from './BoardsStore';
import GameFooter from './GameFooter';
import GameHeader from '../components/GameHeader';
import StartingCountdown from './StartingCountdown';

export default function Game({ gameId }) {
    const setTileValueInBoard = useBoardsStore(state => state.setTileValueInBoard);
    const setBoardsMeta = useBoardsStore(state => state.setBoardsMeta);
    const boardsMetaStore = useBoardsStore(state => state.boardsMeta);
    const setBoardsProgress = useBoardsStore(state => state.setBoardProgress);
    const setGameMeta = useBoardsStore(state => state.setGameMeta);

    useEffect(() => {
        const interval = setInterval(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/v1/games/' + gameId)
            .then(response => {
                setGameMeta(gameId, response.data.state, response.data.winner, response.data.stateProperties.startingCountdown);

                for (let board of response.data.boards) {
                    for (let tile of board.tiles) {
                        setTileValueInBoard(board.id, tile.xPos, tile.yPos, tile.state);
                    }
                    setBoardsMeta(board.id, board.boardOptions.width, board.boardOptions.height, board.playerName, board.boardOptions.colour);
                    setBoardsProgress(
                        board.id,
                        board.boardOptions.playerId,
                        100 - Math.round(board.numberOfRemainingTiles / board.numberOfTiles * 100),
                        board.numberOfRemainingBombs,
                        board.state
                    );
                }
            })
            .catch(error => {
                console.error('There was an error loading latest board update', error);
            });
        }, 500);

        return () => clearInterval(interval);
    }, [gameId]);

    if (boardsMetaStore.keys().length === 0) {
        return <div>Loading game...</div>;
    }

    return (
        <div className="canvas">
            <GameHeader gameId={gameId} />
            <StartingCountdown />
            <div 
                className="game"
                >
                {Array.from(boardsMetaStore.entries()).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).map(([key, boardMeta]) => (
                    <div className="game-board" key={key}>
                        <Board id={key} gameId={gameId} />
                    </div>
                ))}
            </div>
            <GameFooter gameId={gameId} />
        </div>

    );
}
