import PropTypes from 'prop-types';
import useUserSettingsStore from './UserSettingsStore';
import React, { useState } from 'react';
import Cookies from 'js-cookie';

EnterName.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function EnterName(props) {
    const colours = ["#ff99dd", "#ffba69", "#80ffbf", "#80ccff", "#84e184", "#ffff66", "#a366ff", "#ff6666"];
    const username = useUserSettingsStore(state => state.username); // TODO Can we remove?
    
    let cookieUsername = Cookies.get('username');
    let cookieColour = Cookies.get('colour');
    if (cookieColour == undefined) {
        cookieColour = colours[Math.floor(Math.random() * colours.length)];
    }

    const [formUsername, setFormUsername] = useState(cookieUsername);
    const [formColour, setFormColour] = useState(cookieColour);
    
    const { onClose, selectedValue, open } = props;

    const handleInputChange = (event) => {
        setFormUsername(event.target.value);
        Cookies.set('username', event.target.value);
    };

    const updateColour = (colour) => {
        setFormColour(colour);
        Cookies.set('colour', colour);
    }
    
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick" || reason == 'escapeKeyDown') {
            return;
        }
        if (formUsername === "") {
            return;
        }
        
        onClose(formUsername, formColour);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          handleClose();
        }
    };

    if (!open) {
        return null;
    }

    return (
        <div className="modal-background">
            <div className="modal">
                <div className="modal-header">
                    <h1 className="header-text">MineSpeeder</h1>
                </div>
                <div className="modal-body">
                    <input 
                        className="input" 
                        placeholder="Name" 
                        defaultValue={formUsername}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        spellCheck="false"
                    ></input>
                    <div className="colour-picker">
                    {colours.map((colour, index) => (
                        <div 
                            className={formColour === colour ? "colour-picker-option picked" : "colour-picker-option"}
                            style={{"background-color": colour}}
                            onClick={() => updateColour(colour)}>
                        </div>
                    ))}
                    </div>
                    <button className="button" onClick={handleClose}>Join Game</button>
                </div>
                <div className="modal-footer">
                    <p>Made with 🤎 by Ryan Laycock</p>
                </div>
            </div>
        </div>
    )
}
