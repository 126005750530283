import useBoardsStore from './BoardsStore';
import { useShallow } from 'zustand/react/shallow'

export default function StartingCountdown() {
    const gameMeta = useBoardsStore(useShallow(state => state.gameMeta));

    if (gameMeta.gameState === "starting") {
        return (
            <div className="starting-countdown zoom-in" key={gameMeta.startingCountdown}>
                <h1>{gameMeta.startingCountdown === 0 ? "Mine!" : gameMeta.startingCountdown}</h1>
            </div>
        );
    }
}
