import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useBoardsStore from './BoardsStore';
import { useShallow } from 'zustand/react/shallow'
import axios from 'axios';

export default function EndGame(gameId) {
    const gameMeta = useBoardsStore(useShallow(state => state.gameMeta));

    const navigate = useNavigate(); 
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick" || reason == 'escapeKeyDown') {
            return;
        }
    };

    const handleLeaveClick = () => {
        navigate(`/`);
    };
    
    const handleReplayClick = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + '/v1/games/' + gameId.gameId + '/reset', {})
          .catch(error => {
            console.error('There was an error resetting game', error);
          });
    };

    if (gameMeta.gameState !== "finished") {
        return null;
    }
    let open = true

    return (
        <div className="modal-background">
            <div className="modal">
                <div className="modal-header">
                    <h1 className="header-text">MineSpeeder</h1>
                </div>
                <div className="modal-body">
                    <div className="winner-text">
                        <h2 className="winner-name">{gameMeta.winner}</h2>
                        <h2> won the game!</h2>
                    </div>
                    <button className="button" onClick={handleReplayClick}>Play Again</button>
                    <button className="button" onClick={handleLeaveClick}>Quit</button>
                </div>
                <div className="modal-footer">
                    <p>Made with 🤎 by Ryan Laycock</p>
                </div>
            </div>
        </div>
    )
}
