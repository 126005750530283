import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

export default function Home() {
    const navigate = useNavigate();
    const [numberOfBoards, setNumberOfBoards] = useState(1);
    const [height, setHeight] = useState(10);
    const [width, setWidth] = useState(7);
    const [numberOfBombs, setNumberOfBombs] = useState(10);
    const [roomCodeInput, setroomCodeInput] = useState("");

    function createNewGame(height, width, numberOfBombs) {
        axios.post(process.env.REACT_APP_BACKEND_URL + '/v1/games', { 
            "gameOptions": {
                "height": parseInt(height),
                "width": parseInt(width),
                "numberOfBombs": parseInt(numberOfBombs),
            },
        })
        .then(response => {
            navigate(`/game/${response.data.id}`);
        })
        .catch(error => {
            console.error('There was an error creating new game', error);
        });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleJoinCodeClick();
        }
    };

    const handleInputChange = (event) => {
        setroomCodeInput(event.target.value.toUpperCase());
    };
      
    const handleJoinCodeClick = () => {
        if (roomCodeInput === "") {
            return;
        }
        navigate(`/game/${roomCodeInput}`);
    };

    return (
        <div className="modal-background">
            <div className="modal">
                <div className="modal-header">
                    <h1 className="header-text">MineSpeeder</h1>
                </div>
                <div className="modal-body">
                    <button className="button">Quick Play</button>
                    <p>or</p>
                    <button className="button" onClick={() => createNewGame(10, 7, 10)}>Create Game</button>
                    <p>or</p>
                    <input 
                        className="input"
                        value={roomCodeInput}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Room Code"
                    ></input>
                    <button className="button" onClick={handleJoinCodeClick}>Join Game</button>
                </div>
                <div className="modal-footer">
                    <p>Made with 🤎 by Ryan Laycock</p>
                </div>
            </div>
        </div>
    )
}