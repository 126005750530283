import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Game from '../components/Game';
import EnterName from '../components/EnterName';
import EndGame from '../components/EndGame';
import useBoardsStore from '../components/BoardsStore';
import useUserSettingsStore from '../components/UserSettingsStore';

// Refactor to typescript?

export default function GamePage() {
  const location = useLocation();
  const { gameId } = useParams();

  const [openJoin, setOpenJoin] = useState(false);
  const [colour, setColour] = useState("");

  const reset = useBoardsStore(state => state.reset);
  const username = useUserSettingsStore(state => state.username);
  const setUsername = useUserSettingsStore(state => state.setUsername);

  const handleClose = (username, colour) => {
    setOpenJoin(false);
    setUsername(username);
    axios.post(process.env.REACT_APP_BACKEND_URL + '/v1/games/' + gameId + '/boards', { // Add new board
      "height": 10, // TODO Remove these from here, not needed
      "width": 7,
      "numberOfBombs": 10,
      "playerId": username,
      "colour": colour
    })
    .then(response => {
        console.log(response);
    })
    .catch(error => {
        console.error('There was an error adding a new board', error);
    });
  };
  
  useEffect(() => {
    reset(); // Reset the boards store before navigarting to the game
    setOpenJoin(true);
  }, []);

  return (
    <>
    <EnterName
      selectedValue={username}
      open={openJoin}
      onClose={handleClose}
    />
    <EndGame gameId={gameId} />
    <Game gameId={gameId} />
    </>
  );
}
