import { create } from 'zustand'

const useUserSettingsStore = create((set) => ({
    username: "",
    setUsername: (username) => set(() => ({
        username
    })),
}));

export default useUserSettingsStore;
